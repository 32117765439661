import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { ReplyManagementAPI } from "../../../api";
import {
  ReplyManagementRecategorizationEffectsFn,
  ReplyManagementEffects as RME,
} from "./types";
import { trackButtonClick } from "../../../modules/mixpanel";
import { getDefaultClusteringEffects } from "./helpers";

import { ReplyClusterStateContext } from "./reply-cluster-state";

export const ReplyManagementEffectsContext = createContext<RME>(
  getDefaultClusteringEffects().replyManagement
);
export const useReplyManagementEffects = (): RME => {
  const [state, actions] = useContext(ReplyClusterStateContext);

  const { setAsBounced, setAsReplied, setAsAutoreply, getReplyTranslation } =
    ReplyManagementAPI.useReplyManagement();



  useEffect(() => {
    actions.setShowTranslated(false)
  }, [state?.reply?.id])


  const fetchTranslation = useCallback(async (replyId: string) => {
    const result = await getReplyTranslation(replyId);
    return result?.data?.content ?? "";
  }, []);

  const bounced: ReplyManagementRecategorizationEffectsFn = useCallback(
    async (replyId) => {
      actions.resetAssignedOnAndRequestMeta(await setAsBounced(replyId));
    },
    [setAsBounced, actions.resetAssignedOnAndRequestMeta]
  );

  const autoreplied: ReplyManagementRecategorizationEffectsFn = useCallback(
    async (replyId: string) => {
      actions.resetAssignedOnAndRequestMeta(await setAsAutoreply(replyId));
    },
    [setAsAutoreply, actions.resetAssignedOnAndRequestMeta]
  );

  const replied = useCallback(
    async (replyId: string) => {
      actions.resetAssignedOnAndRequestMeta(await setAsReplied(replyId));
    },
    [actions.resetAssignedOnAndRequestMeta, setAsReplied]
  );

  const toggleTranslation: RME["toggleTranslation"] = useCallback(async () => {
    const reply = state?.reply;
  
    if (reply && !reply?.translated) {
      const translation = await fetchTranslation(reply.id);
      actions.setReply({...reply, translated: translation});
    }
    trackButtonClick({
      name: "toggle_translations",
      show_translation: !state.showTranslated,
    });
    actions.setShowTranslated(!state.showTranslated);
  }, [state?.showTranslated, state?.reply?.id, state?.reply?.translated]);

  const toggleOriginalMessage: RME["toggleOriginalMessage"] =
    useCallback(() => {
      trackButtonClick({
        name: "toggle_translations",
        show_translation: !state.showOriginal,
      });

      actions.setShowTranslated(false);
      actions.setShowOriginal(!state.showOriginal);
    }, [state.showOriginal, state.reply]);

  return {
    autoreplied,
    bounced,
    replied,
    toggleTranslation,
    toggleOriginalMessage,
  };
};

export const ReplyManagementEffectsProvider = ({ children }) => {
  const value = useReplyManagementEffects();

  return (
    <ReplyManagementEffectsContext.Provider value={value}>
      {children}
    </ReplyManagementEffectsContext.Provider>
  );
};

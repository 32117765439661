import { useCallback } from "react";
import { HttpFetchResult } from "../../hooks/http/fetch/types";
import { useAuthRequestSender } from "../../hooks/http";
import { API, API_Data } from "../../../../main-service/src/types";
import { E_ClusteringRes } from "../../../../main-service/src/types/shared/api";

const asyncBaseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/clustering/async/replies`;
const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/clustering/replies`;

export type ResTranslation =
  API[API_Data.CLUSTERING_RES][E_ClusteringRes.TRANSLATE_REPLY];

export type ReplyManagementAPI = {
  setAsAutoreply: (replyId: string) => Promise<HttpFetchResult<unknown>>;
  rescheduleSendout: (
    replyId: string,
    nextSendoutDate: string
  ) => Promise<HttpFetchResult<unknown>>;
  setAsBounced: (replyId: string) => Promise<HttpFetchResult<unknown>>;
  setAsReplied: (replyId: string) => Promise<HttpFetchResult<unknown>>;
  getReplyTranslation: (
    replyId: string
  ) => Promise<HttpFetchResult<ResTranslation>>;
};

export const useReplyManagement = (): ReplyManagementAPI => {
  const sendRequest = useAuthRequestSender();

  const getReplyTranslation = useCallback(
    (replyId: string) => {
      return sendRequest<ResTranslation>(`${baseUrl}/translations`, {
        method: "POST",
        body: { reply_id: replyId },
      });
    },
    [sendRequest]
  );

  const setAsAutoreply = useCallback(
    (replyId: string) => {
      return sendRequest(`${asyncBaseUrl}/autoreplied`, {
        method: "POST",
        body: { reply_id: replyId },
      });
    },
    [sendRequest]
  );

  const rescheduleSendout = useCallback(
    (replyId: string, nextSendoutDate: string) => {
      return sendRequest(`${asyncBaseUrl}/reschedule-sendout`, {
        method: "POST",
        body: { reply_id: replyId, send_after: nextSendoutDate },
      });
    },
    [sendRequest]
  );

  const setAsBounced = useCallback(
    (replyId: string) => {
      return sendRequest(`${asyncBaseUrl}/bounced`, {
        method: "POST",
        body: { reply_id: replyId },
      });
    },
    [sendRequest]
  );

  const setAsReplied = useCallback(
    (replyId: string) => {
      return sendRequest(`${asyncBaseUrl}/replied`, {
        method: "POST",
        body: { reply_id: replyId },
      });
    },
    [sendRequest]
  );

  return {
    getReplyTranslation,
    setAsAutoreply,
    rescheduleSendout,
    setAsBounced,
    setAsReplied,
  };
};
